import React from "react";
import Layout from "../components/Layout";
import { getItemFromStore } from "../helpers/hash";
import SEO from "../components/SEO";

function ThankYou() {
  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"Thank You For Reaching Out | LocalTier"}
        description={
          "Local Tier is Your Premier Source for the Best Restaurants, Homes Service Companies, Local Contractors, Retail Shops and Products Made in Sacramento."
        }
      />
      <section className="section bg-gray-50">
        <div className="mx-auto px-4 py-16 pb-24 max-w-lg">
          <h1 className="font-semibold text-2xl md:text-3xl text-center">
            You're all set.
          </h1>
          <p className="text-center text-gray-700">
            Thank you,{" "}
            {getItemFromStore("form") && getItemFromStore("form")["first-name"]}
            ! We have received your get listed submission. Look out for our
            emails. We will be contacting you at the following address:
          </p>
          <div>
            <div className="my-6 bg-white s rounded-xl p-4 shadow-lg">
              <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-2 md:py-3   sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-600">
                    Business Name
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {getItemFromStore("form") &&
                      getItemFromStore("form")["business-name"]}
                  </dd>
                </div>
                <div class="py-2 md:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-sm font-medium text-gray-600">
                    Contact email
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {getItemFromStore("form") && getItemFromStore("form").email}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ThankYou;
